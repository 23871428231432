.autocomplete {
    position: relative;
}
.autocomplete-items {
    list-style-type: none;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    max-height: 200px;
    position: absolute;
    top: 2.5rem;
    margin-top: 0;
    padding: 0;
    overflow: auto;
    width: 11.5rem;
    z-index: 11;
    background: #ffffff;
    color: #111;
}
.autocomplete-items li {
    cursor: pointer;
    padding: 3px;
}
.autocomplete-items li:hover {
    background: rgba(228, 228, 228, 0.267);
}
.autocomplete-selected {
    color: rgb(37, 37, 223);
}

@media screen and (max-width: 720px) {
    .autocomplete-items {
        top: 2.25rem;
    }
}

.bp3-dark .autocomplete-items {
    background: #111;
    color: #eee;
}

.bp3-dark .autocomplete-items li:hover {
    background: rgba(238,238,238,0.1);
}

.bp3-dark .autocomplete-selected {
    color: rgb(37, 211, 223);
}