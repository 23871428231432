.bp3-light .release-notes {
  background: #fafafa;
}
.release-notes-wrapper .release-notes {
  margin: 0.5rem;
  padding: 1rem;
}
.release-notes ul img {
    max-width: 100%;
}
.release-notes-wrapper h1 {
  font-size: 1.5rem !important;
  font-weight: bold;
}
.release-notes-wrapper h2 {
  margin-top: 0.5rem;
  font-size: 1.2rem !important;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.release-notes-wrapper h4 {
  background: rgba(244,244,244,0.1);
  border-radius: 0.25rem;
  margin: 2px;
  padding: 2px 4px;
  font-size: 80%;
  font-weight: normal;
  width: 8rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.release-notes-wrapper h4 img {
  display: inline;
  image-rendering: pixelated;
  height: 100%;
}
.release-notes-wrapper a {
    color: #187daf;
}
.release-notes-wrapper p {
  line-height: 1.4;
  margin-top: 0.5rem;
  display: flex;
}
.release-notes-wrapper p img {
  max-height: 15rem;
  margin: 0 auto;
  max-width: 100%;
}
.release-notes-wrapper li ul {
  margin: 0;
}
.release-notes-wrapper ul {
    list-style-type: disc;
    margin-left: 20px;
}
.release-notes-wrapper li > ul {
    margin-left: 40px;
}

.bp3-dark .release-dialog h1,
.bp3-dark .release-dialog h2,
.bp3-dark .release-dialog h3,
.bp3-dark .release-dialog h4 {
  color: #fff !important;
}
.release-dialog {
  width: 620px !important;
}
@media screen and (max-width: 720px) {
  .release-dialog {
    margin-top: 4rem !important;
    width: 88vw !important;
  }
  .release-notes-wrapper .release-notes {
    padding: 0.25rem !important;
    margin: 0.25rem !important;
  }
}
.no-list-item-types {
  list-style-type: none;
}
.bp3-tag-input {
    align-items: center !important;
}
.bp3-tag-input-icon {
    margin: 0 !important;
    margin-right: 4px !important;
}
.bp3-switch {
    margin-bottom: 0 !important;
}
