.rules-editor-dialog {
    padding-bottom: 0 !important;
}

.rule-no, .rule-delete {
    padding: .25rem;
    text-align: center;
    height: 100%;
    width: 1.5rem;
}

.rule-delete {
    cursor: pointer;
}

.rules-list-item {
    background: rgba(0, 0, 0, 0.05);
    border-radius: .25rem;
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: .25rem 0;
    min-height: 2rem;
}
